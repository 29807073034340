import React from "react";
import { connect } from "react-redux";
import { Card } from "antd";

import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

import { onlineUsersList } from "redux/actions/User";

import { mapStyle } from "./mapStyle";

const MarkerClustererExampleGoogleMap = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={2}
    defaultCenter={{ lat: 25.0391667, lng: 121.525 }}
    defaultOptions={{
      styles: mapStyle,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "satellite"]
      }
    }}
  >
    <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
      {props.markers.map(marker => (
        <Marker
          defaultIcon={require("assets/images/marker.png")}
          position={{ lat: marker.latitude, lng: marker.longitude }}
          key={marker.uid}
        />
      ))}
    </MarkerClusterer>
  </GoogleMap>
));

class OnlineUsers extends React.Component {
  getUsersArray = data => {
    return data
      ? Object.keys(data).map(key => ({
          uid: key,
          latitude: data[key].l[0],
          longitude: data[key].l[1]
        }))
      : [];
  };

  componentDidMount() {
    this.props.onlineUsersList();
  }

  render() {
    return (
      <Card className="gx-card" title={"Online Users"}>
        <MarkerClustererExampleGoogleMap
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          markers={this.getUsersArray(this.props.online_users)}
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, user }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { online_users } = user;
  return { loader, alertMessage, showMessage, authUser, online_users };
};

export default connect(
  mapStateToProps,
  { onlineUsersList }
)(OnlineUsers);
