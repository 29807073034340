import React from 'react';
import {Button, Form, Input, Select, Modal} from 'antd';
import Widget from 'components/Widget';
import {discardEmptyDataFromObject} from '../../../utils/General';

const confirm = Modal.confirm;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
  },
  labelAlign: 'left',
};

const Contact = ({user, form, updateUser}) => {
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        confirm({
          title: 'Are you sure you want to update?',
          content: 'Please confirm if you really want to save the updated values.',
          okText: 'Confirm',
          onOk: () => {
            updateUser(user.uid, discardEmptyDataFromObject(values));
          },
          onCancel() {
            console.log('Canceled update.');
          },
        });
      }
    });
  };

  if (user.userData) {

    const {getFieldDecorator} = form;
    return (
      <Form onSubmit={handleSubmit}>
        <Widget title="Update User" styleName="gx-card-profile-sm">
          <div className="ant-row">
            <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-12">
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  {/* <i className={`fas fa-envelope gx-fs-xlxl gx-text-orange`} /> */}
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Email</h6>
                  {user.email ? (
                    `${user.email}`
                  ) : (
                    <FormItem {...formItemLayout}>
                      {getFieldDecorator('email', {
                        initialValue: user && user.email ? user.email : '',
                        rules: [
                          {
                            required: true,
                            message: 'Please input email!',
                            whitespace: true,
                          },
                        ],
                      })(<Input />)}
                    </FormItem>
                  )}
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  {/* <i className={`fab fa-facebook gx-fs-xlxl gx-text-orange`} /> */}
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Gender</h6>

                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('gender', {
                      initialValue: user && user.userData.gender ? user.userData.gender : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input gender!',
                          whitespace: true,
                        },
                      ],
                    })(
                      <Select>
                        <Select.Option value="male">Male</Select.Option>
                        <Select.Option value="female">Female</Select.Option>
                      </Select>,
                    )}
                  </FormItem>
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  {/* <i className={`fab fa-instagram gx-fs-xlxl gx-text-orange`} /> */}
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Name</h6>

                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('name', {
                      initialValue: user && user.userData.name ? user.userData.name : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input name!',
                          whitespace: true,
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  {/* <i className={`fab fa-linkedin gx-fs-xlxl gx-text-orange`} /> */}
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Phone Number</h6>

                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('phoneNumber', {
                      initialValue:
                        user && user.userData.phoneNumber ? user.userData.phoneNumber : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input phone number!',
                          whitespace: true,
                        },
                      ],
                    })(<Input />)}
                  </FormItem>
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  {/* <i className={`fab fa-pinterest gx-fs-xlxl gx-text-orange`} /> */}
                </div>
                <div className="gx-media-body">
                  {/* <h6 className="gx-mb-1 gx-text-grey">Pinterest</h6> */}
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </div>
              </div>
            </div>

            {/* <div className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-12 ant-col-xl-12">
              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`fab fa-snapchat gx-fs-xlxl gx-text-orange`} />
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Snapchat</h6>
                  {social.snapchat === '' ? (
                    'Not Added'
                  ) : (
                    <p className="gx-mb-0">{social.snapchat}</p>
                  )}
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`fab fa-twitter gx-fs-xlxl gx-text-orange`} />
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Twitter</h6>
                  {social.twitter === '' ? (
                    'Not Added'
                  ) : (
                    <p className="gx-mb-0">{social.twitter}</p>
                  )}
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`fab fa-whatsapp gx-fs-xlxl gx-text-orange`} />
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">WhatsApp</h6>
                  {social.whatsapp === '' ? (
                    'Not Added'
                  ) : (
                    <p className="gx-mb-0">{social.whatsapp}</p>
                  )}
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`fab fa-youtube gx-fs-xlxl gx-text-orange`} />
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Youtube</h6>
                  {social.youtube && social.youtube !== '' ? (
                    <p className="gx-mb-0">{social.youtube}</p>
                  ) : (
                    'Not Added'
                  )}
                </div>
              </div>

              <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                <div className="gx-mr-3">
                  <i className={`fab fa-tiktok gx-fs-xlxl gx-text-orange`} />
                </div>
                <div className="gx-media-body">
                  <h6 className="gx-mb-1 gx-text-grey">Tiktok</h6>
                  {social.tiktok && social.tiktok !== '' ? (
                    <p className="gx-mb-0">{social.tiktok}</p>
                  ) : (
                    'Not Added'
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </Widget>
      </Form>
    );
  } else {
    return null;
  }
};

export default Form.create()(Contact);
