import _ from 'lodash';

/**
 *
 * @param {Object} obj Object from which data is to be discarded
 * @returns {Object} New object without the keys with empty or null vlaue
 */
export const discardEmptyDataFromObject = (obj) => {
  const newObj = _.cloneDeep(obj);

  Object.entries(obj).forEach(([key, value]) => {
    if (!value || value.length === 0) {
      delete newObj[key];
    }
  });

  return newObj;
};
