import React from "react";
import { Route, Switch } from "react-router-dom";
import ManageUser from "./ManageUser";
import UserReadings from "./UserReadings";
import ViewUser from "./ViewUser";
import OnlineUsers from "./OnlineUsers";
import ReportedUsers from "./ReportedUsers";
import PermanentUsers from "./PermanentUsers";
import CreatePermanentUser from "./CreatePermanentUser";
import UpdateUser from "./UpdateUser";
import NotFound from "containers/NotFound";
import ViewReading from "./ViewReading";

const UserRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/manage`} component={ManageUser} />
    <Route path={`${match.url}/userReadings`} component={UserReadings} />
    <Route path={`${match.url}/view/:id`} component={ViewUser} />
    <Route path={`${match.url}/viewReading/:id`} component={ViewReading} />
    <Route path={`${match.url}/online`} component={OnlineUsers} />
    <Route path={`${match.url}/reported`} component={ReportedUsers} />
    <Route path={`${match.url}/permanent/create`} component={CreatePermanentUser} />
    <Route path={`${match.url}/permanent/update/:id`} component={UpdateUser} />
    <Route path={`${match.url}/permanent`} component={PermanentUsers} />
    <Route component={NotFound} />
  </Switch>
);

export default UserRoutes;
