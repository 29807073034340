import { GOT_SPONSORS } from "../../constants/ActionTypes";

const initialSponsors = {
  sponsors: { sponsors_list: [] }
};

export default (state = initialSponsors, action) => {
  switch (action.type) {
    case GOT_SPONSORS: {
      return {
        ...state,
        sponsors: action.payload
      };
    }
    default:
      return state;
  }
};
