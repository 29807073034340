import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Switch,
  Upload,
  message,
  Icon,
  Slider,
  Modal
} from "antd";
import _ from "lodash";
import LocationPicker from "./LocationPicker";

const confirm = Modal.confirm;
const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 }
  },
  labelAlign: "left"
};
const socialProfileLabels = {
  facebook: "Facebook",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  snapchat: "Snapchat",
  twitter: "Twitter",
  whatsapp: "WhatsApp",
  pinterest: "Pinterest",
  tiktok: "Tiktok",
  youtube: "Youtube",
  email: "Email",
  wifi: "WiFi Network",
  menu: "Menu"
};

const defaultSocialProfiles = {
  email: "",
  facebook: "",
  instagram: "",
  linkedin: "",
  snapchat: "",
  twitter: "",
  whatsapp: "",
  pinterest: "",
  tiktok: "",
  youtube: "",
  wifi: {
    verified: true
  },
  wifi_ssid: {
    active: true,
    verified: true
  },
  menu: ""
}

class SponsorUpdateForm extends Component {
  state = {
    imageUrl: "",
    sliderValue: 10,
    sliderMin: 1,
    sliderMax: 1001,
    location: { lat: 25.0391667, lng: 121.525 },
    locationChanged: false
  };

  componentDidMount() {
    let { imageUrl, sliderValue, location } = this.state;
    if (this.props.sponsor && this.props.sponsor.profileData) {
      const data = this.props.sponsor.profileData;
      if (data.avatar) {
        imageUrl = data.avatar;
      }
      if (data.radius) {
        sliderValue = data.radius;
      }
      if (data.location) {
        location = data.location;
      }
      this.setState({ imageUrl, sliderValue, location });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && this.props.sid != null) {
        confirm({
          title: "Are you sure you want to update?",
          content:
            "Please confirm if you really want to save the updated values.",
          okText: "Confirm",
          onOk: () => {
            var sponsor = _.merge(this.props.sponsor, values.sponsor);
            if (this.state.locationChanged === true) {
              sponsor.profileData.location = this.state.location;
            }
            this.props.updateSponsor(
              this.props.sid,
              sponsor,
              values.profile_photo
            );
          },
          onCancel() {
            console.log("Canceled update.");
          }
        });
      }
    });
  };

  beforeUpload = file => {
    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPG) {
      message.error("You can only upload JPG or PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJPG && isLt2M;
  };

  onFileSelected = params => {
    this.setState({
      imageUrl: URL.createObjectURL(params.file)
    });
  };

  renderWiFiProfile = wifiProfilesKeys => {
    let socialProfile = "wifi";
    let socialProfile_1 = "wifi_ssid";
    const { getFieldDecorator } = this.props.form;
    const { sponsor } = this.props;
    let label = socialProfileLabels[socialProfile];
    return (
      <div key={socialProfile}>

        <Row>
          <Col span={24}>
            <h4>{label}</h4>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="ID">
              {getFieldDecorator(
                "sponsor.profileData.socialProfiles." +
                  socialProfile +
                  ".userId",
                {
                  initialValue:
                    sponsor.profileData.socialProfiles &&
                    sponsor.profileData.socialProfiles[socialProfile] &&
                    sponsor.profileData.socialProfiles[socialProfile].userId
                      ? sponsor.profileData.socialProfiles[socialProfile].userId
                      : ""
                }
              )(<Input />)}
            </FormItem>
          </Col>

          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="Password">
              {getFieldDecorator(
                "sponsor.profileData.socialProfiles." +
                  socialProfile_1 +
                  ".userId",
                {
                  initialValue:
                    sponsor.profileData.socialProfiles &&
                    sponsor.profileData.socialProfiles[socialProfile_1] &&
                    sponsor.profileData.socialProfiles[socialProfile_1].userId
                      ? sponsor.profileData.socialProfiles[socialProfile_1].userId
                      : ""
                }
              )(<Input />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator(
                "sponsor.profileData.socialProfiles." +
                  socialProfile +
                  ".active",
                {
                  initialValue:
                    sponsor.profileData.socialProfiles &&
                    sponsor.profileData.socialProfiles[socialProfile] &&
                    sponsor.profileData.socialProfiles[socialProfile].active
                      ? sponsor.profileData.socialProfiles[socialProfile].active
                      : false,
                  valuePropName: "checked"
                }
              )(<Switch />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  };

  renderSocialProfile = socialProfile => {
    const { getFieldDecorator } = this.props.form;
    const { sponsor } = this.props;
    const label = socialProfileLabels[socialProfile];
    return (
      <div key={socialProfile}>
        <Row>
          <Col span={24}>
            <h4>{label}</h4>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="ID">
              {getFieldDecorator(
                "sponsor.profileData.socialProfiles." +
                  socialProfile +
                  ".userId",
                {
                  initialValue:
                    sponsor.profileData.socialProfiles &&
                    sponsor.profileData.socialProfiles[socialProfile] &&
                    sponsor.profileData.socialProfiles[socialProfile].userId
                      ? sponsor.profileData.socialProfiles[socialProfile].userId
                      : ""
                }
              )(<Input />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator(
                "sponsor.profileData.socialProfiles." +
                  socialProfile +
                  ".active",
                {
                  initialValue:
                    sponsor.profileData.socialProfiles &&
                    sponsor.profileData.socialProfiles[socialProfile] &&
                    sponsor.profileData.socialProfiles[socialProfile].active
                      ? sponsor.profileData.socialProfiles[socialProfile].active
                      : false,
                  valuePropName: "checked"
                }
              )(<Switch />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <FormItem {...formItemLayout} label="Verified">
              {getFieldDecorator(
                "sponsor.profileData.socialProfiles." +
                  socialProfile +
                  ".verified",
                {
                  initialValue:
                    sponsor.profileData.socialProfiles &&
                    sponsor.profileData.socialProfiles[socialProfile] &&
                    sponsor.profileData.socialProfiles[socialProfile].verified
                      ? sponsor.profileData.socialProfiles[socialProfile]
                          .verified
                      : false,
                  valuePropName: "checked"
                }
              )(<Switch />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  };

  onSliderChange = value => {
    this.setState({ sliderValue: value });
  };

  onLocationChange = location => {
    this.setState({ location, locationChanged: true });
  };

  getSliderDisplayValue = value => {
    let sliderDisplayValue = value;
    if (sliderDisplayValue === this.state.sliderMax) {
      sliderDisplayValue = "Global";
    } else {
      sliderDisplayValue += "km";
    }
    return sliderDisplayValue;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { sponsor } = this.props;
    let { imageUrl, sliderValue, sliderMin, sliderMax, location } = this.state;

    const sliderDisplayValue = this.getSliderDisplayValue(sliderValue);

    const uploadButton = (
      <div>
        <Icon type={"plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    let socialProfiles = null;
    let wifiProfiles = null;

    if (sponsor.profileData && sponsor.profileData.socialProfiles) {
      let wifiProfilesKeys = [];
      socialProfiles = Object.keys(defaultSocialProfiles).map(
        key => {
          if(key !== "wifi" && key !=="wifi_ssid") {
            return this.renderSocialProfile(key);
          } else {
            wifiProfilesKeys.push(key);
            return null;
          }
        }
      );

      if (wifiProfilesKeys.length === 2) {
        wifiProfiles = this.renderWiFiProfile(wifiProfilesKeys);
      }
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col lg={6} md={6} sm={12} xs={18}>
            <FormItem {...formItemLayout} label="Name">
              {getFieldDecorator("sponsor.profileData.name", {
                initialValue:
                  sponsor.profileData && sponsor.profileData.name
                    ? sponsor.profileData.name
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Please input sponsor's name!",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={12} xs={6}>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator("sponsor.active", {
                initialValue: sponsor.active ? sponsor.active : false,
                valuePropName: "checked"
              })(<Switch />)}
            </FormItem>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="Status">
              {getFieldDecorator("sponsor.profileData.status", {
                initialValue:
                  sponsor.profileData && sponsor.profileData.status
                    ? sponsor.profileData.status
                    : ""
              })(<TextArea autosize={{ minRows: 5, maxRows: 5 }} />)}
            </FormItem>
          </Col>

          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="Profile Photo">
              {getFieldDecorator("profile_photo", {
                valuePropName: "file"
              })(
                <Upload
                  name="profile_photo"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.onFileSelected}
                >
                  {imageUrl ? <img src={imageUrl} alt="" /> : uploadButton}
                </Upload>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <FormItem {...formItemLayout} label="Broadcasting Radius">
              {getFieldDecorator("sponsor.profileData.radius", {
                initialValue: sliderValue
              })(
                <Row>
                  <Col lg={4} md={4} sm={6} xs={6}>
                    <Input disabled={true} value={sliderDisplayValue} />
                  </Col>
                  <Col lg={20} md={20} sm={18} xs={18}>
                    <Slider
                      min={sliderMin}
                      max={sliderMax}
                      onChange={this.onSliderChange}
                      tipFormatter={this.getSliderDisplayValue}
                      value={sliderValue}
                    />
                  </Col>
                </Row>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <LocationPicker
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `300px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              onLocationChange={this.onLocationChange}
              location={location}
              circleRadius={sliderValue}
              maxOutRadius={sliderMax}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <h3 style={{ marginTop: "20px" }}>Social Profiles</h3>
          </Col>
        </Row>

        {socialProfiles}
        {wifiProfiles}

        <Row>
          <Col span={24}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(SponsorUpdateForm);
