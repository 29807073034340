import React from "react";
import { Switch, Route } from "react-router-dom";

import MainRouts from "./main";
import UserRoutes from "./user";
import SponsorRoutes from "./sponsor";
import AnalyticsRoutes from "./analytics";
import NotFound from "containers/NotFound";

const BeApp = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={MainRouts} />
      <Route path={`${match.url}user`} component={UserRoutes} />
      <Route path={`${match.url}sponsor`} component={SponsorRoutes} />
      <Route path={`${match.url}analytics`} component={AnalyticsRoutes} />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default BeApp;
