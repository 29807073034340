import React, { Component } from "react";
import { Button, Form, Input } from "antd";
import _ from "lodash";

const FormItem = Form.Item;

class SponsorCreateForm extends Component {
  state = {
    sponsor: {
      active: false,
      profileData: {
        name: "",
        avatar: "",
        status: "",
        socialProfiles: {
          email: "",
          facebook: "",
          instagram: "",
          linkedin: "",
          snapchat: "",
          twitter: "",
          whatsapp: "",
          pinterest: "",
          tiktok: "",
          youtube: "",
          wifi: {
            verified: true
          },
          wifi_ssid: {
            active: true,
            verified: true
          }
        }
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var sponsor = _.merge(this.state.sponsor, values.sponsor);
        this.setState({ sponsor });
        this.props.createSponsor(this.state.sponsor);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { sponsor } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Name">
          {getFieldDecorator("sponsor.profileData.name", {
            initialValue: sponsor.profileData.name,
            rules: [
              {
                required: true,
                message: "Please input sponsor's name!",
                whitespace: true
              }
            ]
          })(<Input />)}
        </FormItem>

        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Create & Proceed
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(SponsorCreateForm);
