import fb from "../../firebase/firebase";
import { GOT_USER_ANALYTICS, GOT_SPONSOR_ANALYTICS, GOT_PRODUCT_ANALYTICS } from "../../constants/ActionTypes";
import { USER_ANALYTICS, SPONSOR_ANALYTICS, PRODUCT_ANALYTICS } from "../../constants/AppConstants";


const getData = async (id, type) => {
  let ref = null;
  switch (type) {
    case USER_ANALYTICS:
      ref = fb.user(id)
      break;
    case SPONSOR_ANALYTICS:
      ref = fb.sponsor(id)
      break;
    case PRODUCT_ANALYTICS:
      ref = fb.user(id)
      break;
    default:
      ref = null;
      break;
  }
  
  if(ref !== null) {
    return ref
      .once("value")
      .then(function(snapshot) {
        return snapshot.val();
      })
      .catch(err => {
        return null;
      });
  } else {
    return null;
  }
};

const processAnalyticsList = async (pagination, list, type) => {
  const analytics_list = [];
  if (list) {
    const keysList = Object.keys(list);

    for (const key of keysList) {
      const item = list[key];

      let uData = await getData(key, type);
      if (uData) {
        analytics_list.push({ uid: key, item: uData, ...item });
      }
    }

    if (pagination.lastPage < pagination.current) {
      pagination.total = pagination.total + keysList.length;
    }
    pagination.firstKey = analytics_list[0].uid;
    pagination.lastKey = analytics_list[analytics_list.length - 1].uid;
    if (analytics_list.length > pagination.pageSize) {
      analytics_list.pop();
    }

    if (pagination.current !== undefined) {
      pagination.lastPage = pagination.current;
    }
  }
  return { pagination, analytics_list };
};

export const loadAnalytics = (pagination, type) => async dispatch => {
  let { current, pageSize, lastKey, firstKey, lastPage } = pagination;

  if (current === 1) {
    lastKey = " ";
  }

  let ref = null;
  switch (type) {
    case USER_ANALYTICS:
      ref = fb.userAnalytics()
      break;
    case SPONSOR_ANALYTICS:
      ref = fb.sponsorAnalytics()
      break;
    case PRODUCT_ANALYTICS:
      ref = fb.productAnalytics()
      break;
    default:
      ref = null;
      break;
  }

  if ( ref !== null && (lastPage < current || current === 1)) {
    return ref
      .orderByKey()
      .limitToFirst(pageSize + 1)
      .startAt(lastKey)
      .once("value")
      .then(async function(snapshot) {
        let data = await processAnalyticsList(pagination, snapshot.val(), type);
        dispatch(loadAnalyticsSuccess(data, type));
      });
  } else {
    return ref
      .orderByKey()
      .limitToLast(pageSize + 1)
      .endAt(firstKey)
      .once("value")
      .then(async function(snapshot) {
        let data = await processAnalyticsList(pagination, snapshot.val());
        dispatch(loadAnalyticsSuccess(data, type));
      });
  }
};

const loadAnalyticsSuccess = (analytics, type) => {
  switch (type) {
    case USER_ANALYTICS:
      return { type: GOT_USER_ANALYTICS, payload: analytics }
    case SPONSOR_ANALYTICS:
      return { type: GOT_SPONSOR_ANALYTICS, payload: analytics }
    case PRODUCT_ANALYTICS:
      return { type: GOT_PRODUCT_ANALYTICS, payload: analytics }
    default:
      return null;
  }
};