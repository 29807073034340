import fb from "../../firebase/firebase";
import {
  GOT_USERS,
  GOT_ONLINE_USERS,
  GOT_REPORTED_USERS,
  USER_UPDATED,
  GOT_PERMANENT_USERS,
  USER_DATA,
} from "../../constants/ActionTypes";

const processUsersList = (pagination, list, searchUid = "") => {
  const users_list = [];
  if (list) {
    const keysList = Object.keys(list);
    keysList.forEach((key) => {
      const user = list[key];
      users_list.push({ uid: key, ...user });
    });

    if (searchUid === "") {
      if (pagination.lastPage < pagination.current) {
        pagination.total = pagination.total + users_list.length;
      }
      pagination.firstKey = users_list[0].uid;
      pagination.lastKey = users_list[users_list.length - 1].uid;
      if (users_list.length > pagination.pageSize) {
        users_list.pop();
      }

      if (pagination.current !== undefined) {
        pagination.lastPage = pagination.current;
      }
    }
  }
  return { pagination, users_list };
};

export const usersList = (pagination, searchUid = "") => async (dispatch) => {
  let { current, pageSize, lastKey, firstKey, lastPage } = pagination;

  if (current === 1) {
    lastKey = " ";
  }

  if (searchUid !== "") {
    return fb
      .users()
      .where("role", "!=", "admin")
      .get()
      .then((snapshot) => {
        let users = [];
        snapshot.forEach(function(doc) {
          users.push(doc.data());
        });
        dispatch(
          loadUsersListSuccess(processUsersList(pagination, users, searchUid))
        );
      });
  } else if (lastPage < current || current === 1) {
    return fb
      .users()
      .where("role", "!=", "admin")
      .get()
      .then((snapshot) => {
        let users = [];
        snapshot.forEach(function(doc) {
          users.push(doc.data());
        });
        // fb.addUser()
        dispatch(loadUsersListSuccess(processUsersList(pagination, users)));
      });
  } else {
    return fb
      .users()
      .where("role", "!=", "admin")
      .get()
      .then((snapshot) => {
        let users = [];
        snapshot.forEach(function(doc) {
          users.push(doc.data());
        });
        dispatch(loadUsersListSuccess(processUsersList(pagination, users)));
      });
  }
};

export const userData = (uid) => async (dispatch) => {
  return fb
    .user(uid)
    .get()
    .then(function(snapshot) {
      dispatch({
        type: USER_DATA,
        payload: snapshot.data(),
      });
      return snapshot.data();
    })
    .catch((err) => {
      return null;
    });
};

export const onlineUsersList = () => async (dispatch) => {
  return fb
    .onlineUsers()
    .orderByKey()
    .on("value", (snapshot) => {
      dispatch(loadOnlineUsersSuccess(snapshot.val()));
    });
};

export const reportedUsersList = () => async (dispatch) => {
  return fb
    .reportedUsers()
    .orderByKey()
    .once("value")
    .then((snapshot) => {
      dispatch(loadReportedUsersSuccess(snapshot.val()));
    });
};

export const updateUserStatus = (uid, status) => async (dispatch) => {
  var userUpdate = {};
  userUpdate["active"] = status;

  return fb
    .user(uid)
    .set(userUpdate, { merge: true })
    .then(function(snapshot) {
      dispatch(updateUserInList(uid, userUpdate));
      return true;
    })
    .catch(function(error) {
      return "Updation failed: " + error.message;
    });
};

// const uploadUserAvatar = (uid, profile_photo) => {
//   try {
//     const image = fb.userStorage(uid).child('avatar');
//     return image.put(profile_photo.file.originFileObj).then(snapshot => {
//       return image.getDownloadURL().then(url => {
//         return url;
//       });
//     });
//   } catch (err) {
//     return null;
//   }
// };

export const updateUser = (uid, user) => async (dispatch) => {
  return fb
    .user(uid)
    .set({ userData: user }, { merge: true })
    .then(function() {
      return { uid, user };
    })
    .catch(function(error) {
      return { uid, user };
    });
};

export const changeStatusOfUser = (uid, status) => async (dispatch) => {
  return fb
    .user(uid)
    .set({ active: status }, { merge: true })
    .then(function() {
      // dispatch()
    })
    .catch(function(error) {
      // return {uid, user};
    });
};

export const updateUserLocation = (uid, location) => async (dispatch) => {
  var locationObj = {};
  locationObj["l"] = [location.lat, location.lng];
  locationObj["isPermanent"] = true;
  locationObj["timestamp"] = new Date().getTime();

  return fb
    .userLocation(uid)
    .set(locationObj)
    .then(function() {
      return { uid, location };
    })
    .catch(function(error) {
      return { uid, location };
    });
};

export const userLocation = (uid) => async (dispatch) => {
  return fb
    .userLocation(uid)
    .once("value")
    .then(function(snapshot) {
      const data = snapshot.val();
      return { lat: data.l[0], lng: data.l[1] };
    })
    .catch(function(error) {
      return null;
    });
};

export const permanentUsersList = (pagination) => async (dispatch) => {
  let { current, pageSize, lastKey, firstKey, lastPage } = pagination;

  if (current === 1) {
    lastKey = " ";
  }

  if (lastPage < current || current === 1) {
    return fb
      .permanentUsers()
      .orderByKey()
      .limitToFirst(pageSize + 1)
      .startAt(lastKey)
      .once("value")
      .then(function(snapshot) {
        dispatch(
          loadPermanentUsersSuccess(
            processUsersList(pagination, snapshot.val())
          )
        );
      });
  } else {
    return fb
      .permanentUsers()
      .orderByKey()
      .limitToLast(pageSize + 1)
      .endAt(firstKey)
      .once("value")
      .then(function(snapshot) {
        dispatch(
          loadPermanentUsersSuccess(
            processUsersList(pagination, snapshot.val())
          )
        );
      });
  }
};

export const createPermanentUser = (user) => async (dispatch) => {
  return fb
    .createUser(user)
    .once("value")
    .then(function(snapshot) {
      const user = snapshot.val();
      const uid = snapshot.key;
      var data = {};
      data["createdAt"] = new Date().getTime();
      return fb
        .permanentUser(uid)
        .set(data)
        .then(function(pSnapshot) {
          return { uid, user };
        })
        .catch(function() {
          fb.user(uid).remove();
          return false;
        });
    });
};

export const deleteUser = (uid) => async (dispatch) => {
  const userRef = fb.user(uid);
  return userRef
    .once("value")
    .then(function(snapshot) {
      const user = snapshot.val();
      if (user.isPermanent && user.isPermanent === true) {
        return userRef
          .remove()
          .then(async function() {
            await fb.userLocation(uid).remove();
            await fb.permanentUser(uid).remove();
            await fb.deleteDirectoryContent(fb.userStorage(uid));
            return true;
          })
          .catch(function(error) {
            return "Deletion failed: " + error.message;
          });
      }
    })
    .catch((err) => {
      return "Deletion failed: " + err.message;
    });
};

export const fetchReading = (docPath) => {
  return async (dispatch) => {
    try {
      const res = await docPath.get();

      return res.data();
    } catch (error) {
      throw error;
    }
  };
};

const loadUsersListSuccess = (users) => ({
  type: GOT_USERS,
  payload: users,
});

const loadOnlineUsersSuccess = (online_users) => ({
  type: GOT_ONLINE_USERS,
  payload: online_users,
});

const loadReportedUsersSuccess = (reported_users) => ({
  type: GOT_REPORTED_USERS,
  payload: reported_users,
});

const loadPermanentUsersSuccess = (permanent_users) => ({
  type: GOT_PERMANENT_USERS,
  payload: permanent_users,
});

const updateUserInList = (uid, userUpdate) => ({
  type: USER_UPDATED,
  payload: { uid, userUpdate },
});
