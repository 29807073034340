import _ from "lodash";
import {
  GOT_USERS,
  GOT_ONLINE_USERS,
  GOT_REPORTED_USERS,
  USER_UPDATED,
  GOT_PERMANENT_USERS,
  USER_DATA,
} from "../../constants/ActionTypes";

const initialUsers = {
  users: { users_list: [] },
  online_users: {},
  reported_users: {},
  permanent_users: { users_list: [] },
  user: null,
};

export default (state = initialUsers, action) => {
  switch (action.type) {
    case GOT_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case GOT_ONLINE_USERS: {
      return {
        ...state,
        online_users: action.payload,
      };
    }
    case GOT_REPORTED_USERS: {
      return {
        ...state,
        reported_users: action.payload,
      };
    }
    case GOT_PERMANENT_USERS: {
      return {
        ...state,
        permanent_users: action.payload,
      };
    }
    case USER_DATA: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case USER_UPDATED: {
      const { uid, userUpdate } = action.payload;
      let oldUserIndex = _.findIndex(state.users.users_list, function(o) {
        return o.uid === uid;
      });
      if (oldUserIndex > -1) {
        let oldUser = state.users.users_list[oldUserIndex];
        let newUser = { ...oldUser, ...userUpdate };
        state.users.users_list[oldUserIndex] = newUser;
      }
      return state;
    }
    default:
      return state;
  }
};
