import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Table, Button, Input, Icon, message } from "antd";
import _ from "lodash";

import { userData, updateUserStatus } from "redux/actions/User";
import moment from "moment";

class UserReadings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      pagination: {
        pageSize: 1000,
        current: 1,
        total: 0,
        lastKey: "",
        firstKey: "",
        lastPage: 0,
      },
      loading: false,
      searchUid: "",
    };

    this.columns = [
      {
        title: "Date",
        dataIndex: "timestamp",
        render: (text, record, index) => {
          const date = parseInt(text, 10);
          return moment(date).format("MMMM Do YYYY");
        },
        width: "85%",
      },
      {
        title: "Actions",
        dataIndex: "update",
        width: "15%",
        render: (update, readingData) => {
          const uid = this.props.authUser;

          return (
            <div key={`${readingData.timestamp}`}>
              <Link
                to={{
                  pathname: `/user/viewReading/${uid}`,
                  readingData: readingData,
                }}
              >
                <Button type="primary" size="small">
                  View Reading
                </Button>
              </Link>
            </div>
          );
        },
      },
    ];
  }

  async componentDidMount() {
    let user = await this.props.userData(this.props.authUser);
    if (user) {
      this.setState({
        user: user,
        loading: false,
      });
    } else {
      message.error("User not found.");
      this.setState({ notFound: true });
    }
  }


  render() {
    console.log("this.props.authUser", this.props.authUser);
    
    const readingsObj = (this.state.user && this.state.user.readings) || [];

    const readingsList = Object.keys(readingsObj).map((key) => ({
      timestamp: key,
      ref: readingsObj[key],
    }));

    return (
      <Card className="gx-card" title="Readings List">
        <Table
          columns={this.columns}
          size={"small"}
          dataSource={_.orderBy(readingsList, ["timestamp"], ["desc"])}
          rowKey={(reading) => reading.timestamp}
          loading={this.state.loading}
          bordered
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, user }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  { userData, updateUserStatus }
)(UserReadings);
