import { combineReducers } from "redux";
import Auth from "./Auth";
import Settings from "./Settings";
import User from "./User";
import Sponsor from "./Sponsor";
import Analytics from "./Analytics";

const reducers = combineReducers({
  auth: Auth,
  settings: Settings,
  user: User,
  sponsor: Sponsor,
  analytics: Analytics
});

export default reducers;
