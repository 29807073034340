import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Table, Tag, Button, Popconfirm } from "antd";

import { permanentUsersList, deleteUser, userData } from "redux/actions/User";

class PermanentUsers extends React.Component {

  columns = [
    {
      title: "ID",
      dataIndex: "uid",
      width: "30%"
    },
    {
      title: "Name",
      dataIndex: "userData.name",
      width: "20%"
    },
    {
      title: "Active",
      dataIndex: "active",
      width: "30%",
      render: (active, user) => {
        let color = "";
        let text = "";
        if (active === true) {
          color = "green";
          text = "Yes";
        } else if (active === false) {
          color = "red";
          text = "No";
        }
        return (
          <span>
            <Tag color={color} key={user.uid}>
              {text}
            </Tag>
          </span>
        );
      }
    },
    {
      title: "Actions",
      dataIndex: "isPermanent",
      width: "20%",
      render: (isPermanent, user) => {
        let uid = user.uid;
        if(isPermanent && isPermanent === true) {
          return (<div key={uid}>
            <Link to={`permanent/update/${uid}`}>
              <Button type="primary" size="small">
                Edit
              </Button>
            </Link>
            &nbsp;
            <Popconfirm
              placement="left"
              title="Are you sure you want to delete this user?"
              onConfirm={() => this.confirm(uid)}
              onCancel={() => this.cancel(uid)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" size="small">
                Delete
              </Button>
            </Popconfirm>
          </div>);
        } else {
          return null;
        }
      }
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      users_list: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        lastKey: "",
        firstKey: "",
        lastPage: 0
      },
      loading: false
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.onCollectionUpdate({
      pagination,
      filters,
      sorter
    });
  };

  onCollectionUpdate(params = {}) {
    this.setState({ loading: true });
    const pagination = { ...this.state.pagination };
    if (params.pagination) {
      pagination.current = params.pagination.current;
    }

    this.props.permanentUsersList(pagination);
  }

  componentDidMount() {
    this.onCollectionUpdate();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.permanent_users &&
      nextProps.permanent_users.users_list &&
      nextProps.permanent_users.pagination
    ) {
      const pagination = nextProps.permanent_users.pagination;
      const users_list = nextProps.permanent_users.users_list;

      this.setState({
        loading: false,
        users_list,
        pagination
      });
    }
  }

  confirm = async (uid, status) => {
    this.setState({ loading: true });
    let result = await this.props.deleteUser(uid, status);
    if (result === true) {
      this.onCollectionUpdate();
    } else {
      this.setState({ loading: false });
      alert(result);
    }
  };

  cancel = uid => {
    // delete canceled
  };

  fetchUser = uid => {
    let {users_list} = this.state;
    let i = _.findIndex(this.state.users_list, ['uid', uid]);
    if(i>=0 && !users_list[i].isRetrieved) {
      this.props.userData(uid).then(user => {
          users_list[i] = {isRetrieved: true, ...user, ...users_list[i]};
          this.setState({users_list});
      });
    }
  }

  render() {
    return (
      <Card 
        className="gx-card" 
        title="Permanent Users" 
        extra={
          <Link to={`/user/permanent/create`}>
            <Button
              className="gx-btn-block ant-btn"
              type="primary"
              aria-label="add"
            >
              <i className="icon icon-add gx-mr-2" />
              <span>Add</span>
            </Button>
          </Link>
        }
      >
        <Table
          columns={this.columns}
          size={"small"}
          dataSource={this.state.users_list}
          rowKey={record => record.uid}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          bordered
          onRow={(record, rowIndex) => {
            this.fetchUser(record.uid);
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, user }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { permanent_users } = user;
  return { loader, alertMessage, showMessage, authUser, permanent_users };
};

export default connect(
  mapStateToProps,
  { permanentUsersList, deleteUser, userData }
)(PermanentUsers);
