import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Table, Button, Input, Icon } from "antd";
import _ from "lodash";

import { fetchReading } from "redux/actions/User";
import moment from "moment";
import { Spin } from "../../utils";
import Iframe from "../../components/Iframe";

class ViewReading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      reading: null,
    };
  }

  async componentDidMount() {
    if (!this.props.location.readingData) {
      return;
    }

    this.setState({
      loading: true,
    });

    const readingRef = this.props.location.readingData.ref;

    const reading = await this.props.fetchReading(readingRef);

    if (reading) {
      this.setState({
        loading: false,
        reading,
      });
    }
  }

  render() {
    console.log("this.state.reading:", this.state.reading);

    const name = this.props.user ? this.props.user.userData.name : "";

    if (this.props.location.readingData) {
      if (this.state.loading) {
        return (
          <Spin tip="Please wait ..." spinning={this.state.loading}></Spin>
        );
      }

      return (
        <Card className="gx-card" title="READING">
          <Iframe data={this.state.reading} name={name} />
        </Card>
      );
    } else {
      return (
        <Redirect to={`/user/userReadings/${this.props.match.params.id}`} />
      );
    }
  }
}

const mapStateToProps = ({ auth, user: userState }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { user } = userState;

  return { loader, alertMessage, showMessage, authUser, user };
};

export default connect(
  mapStateToProps,
  { fetchReading }
)(ViewReading);
