import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Card, Spin } from "antd";

import UserCreateForm from "./UserCreateForm";
import { createPermanentUser } from "redux/actions/User";

class CreatePermanentUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sid: null,
      user: {},
      loading: false
    };
  }

  createUser = async user => {
    this.setState({ loading: true });
    let response = await this.props.createPermanentUser(user);
    this.setState({
      uid: response.uid,
      user: response.user,
      loading: false
    });
  };

  render() {
    return (
      <Card className="gx-card" title="Create Permanent User Profile">
        <Spin tip="Please wait ..." spinning={this.state.loading}>
          {this.state.uid === undefined || this.state.uid === null ? (
            <UserCreateForm createUser={this.createUser} />
          ) : (
            <Redirect to={"update/" + this.state.uid + ""} />
          )}
        </Spin>
      </Card>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  { createPermanentUser }
)(CreatePermanentUser);
