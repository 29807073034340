import React, { useState } from "react";
import graphHtml from "../assets/graphHtml";
import moment from "moment";

const Iframe = (props) => {
  const [iframe_ref, setIframe_ref] = useState(null);

  const newData = [
    -114,
    -104,
    -146,
    -126,
    -71,
    -64,
    -98,
    -83,
    89,
    330,
    405,
    235,
    -44,
    -205,
    -211,
    -143,
    -90,
    -67,
    -82,
    -95,
    -74,
    -16,
    2,
    -34,
    -74,
    -75,
    -41,
    -28,
    -29,
    -37,
    -8,
    16,
    37,
    36,
    28,
    18,
    56,
    123,
    174,
    146,
    81,
    36,
    21,
    -10,
    -73,
    -118,
    -145,
    -153,
    -153,
    -121,
    -93,
    -83,
    -96,
    -94,
    -73,
    -53,
    -45,
    -72,
    -108,
    -132,
    -132,
    -125,
    -102,
    -93,
    -88,
    -93,
    -99,
    -116,
    -108,
    -85,
    -72,
    -74,
    -88,
    -113,
    -104,
    -66,
    -46,
    -59,
    -100,
    -103,
    -84,
    -81,
    -121,
    -147,
    -146,
    -118,
    -103,
    -96,
    -99,
    -93,
    -88,
    -75,
    -50,
    -41,
    -37,
    -35,
    -40,
    -66,
    -73,
    -70,
    -60,
    -64,
    -81,
    -95,
    -83,
    -84,
    -99,
    -89,
    -76,
    -96,
    -113,
    -19,
    219,
    415,
    337,
    52,
    -187,
    -228,
    -160,
    -87,
    -53,
    -50,
    -71,
    -110,
    -115,
    -77,
    -17,
    -1,
    -19,
    -50,
    -52,
    -38,
    12,
    57,
    56,
    9,
    -14,
    34,
    115,
    128,
    71,
    67,
    135,
    169,
    111,
    30,
    -10,
    -24,
    -83,
    -145,
    -161,
    -136,
    -139,
    -165,
    -161,
    -135,
    -128,
    -162,
    -181,
    -156,
    -94,
    -70,
    -74,
    -95,
    -102,
    -117,
    -126,
    -133,
    -119,
    -97,
    -96,
    -125,
    -150,
    -139,
    -110,
    -95,
    -120,
    -134,
    -134,
    -127,
    -138,
    -139,
    -123,
    -98,
    -95,
    -101,
    -107,
    -113,
    -104,
    -93,
    -96,
    -131,
    -137,
    -112,
    -86,
    -105,
    -128,
    -98,
    -49,
    -50,
    -74,
    -94,
    -80,
    -60,
    -52,
    -51,
    -63,
    -82,
    -110,
    -116,
    -111,
    -118,
    -159,
    -159,
    -16,
    243,
    393,
    280,
    8,
    -173,
    -176,
    -105,
    -66,
    -78,
    -92,
    -101,
    -104,
    -111,
    -128,
    -145,
    -124,
    -73,
    -34,
    -16,
    -12,
    -4,
    12,
    34,
    47,
    68,
    69,
    82,
    106,
    117,
    89,
    71,
    85,
    96,
    53,
    -24,
    -69,
    -77,
    -103,
    -138,
    -139,
    -111,
    -87,
    -107,
    -143,
    -163,
    -143,
    -121,
    -93,
    -66,
    -64,
    -102,
    -155,
    -164,
    -124,
    -91,
    -106,
    -112,
    -88,
    -67,
    -99,
    -121,
    -110,
    -70,
    -74,
    -111,
    -119,
    -95,
    -87,
    -107,
    -103,
    -87,
    -94,
    -132,
    -135,
    -86,
    -57,
    -101,
    -125,
    -91,
    -60,
    -96,
    -137,
    -125,
    -74,
    -36,
    -44,
    -47,
    -41,
    -35,
    -46,
    -43,
    -51,
    -72,
    -125,
    -133,
    -97,
    -60,
    -90,
    -146,
    -153,
    -110,
    -84,
    -121,
    -151,
    -65,
    170,
    403,
    430,
    200,
    -94,
    -244,
    -204,
    -113,
    -66,
    -47,
    -44,
    -49,
    -57,
    -64,
    -65,
    -40,
    -24,
    -20,
    -28,
    -26,
    -17,
    8,
    13,
    27,
    45,
    44,
    42,
    56,
    88,
    115,
    136,
    134,
    129,
    107,
    73,
    18,
    -28,
    -61,
    -85,
    -118,
    -149,
    -160,
    -146,
    -127,
    -121,
    -119,
    -125,
    -125,
    -132,
    -146,
    -161,
    -139,
    -98,
    -65,
    -96,
    -136,
    -132,
    -109,
    -114,
    -158,
    -185,
    -169,
    -130,
    -116,
    -105,
    -88,
    -80,
    -98,
    -108,
    -105,
    -68,
    -43,
    -38,
    -32,
    -36,
    -70,
    -112,
    -109,
    -82,
    -63,
    -77,
    -81,
    -59,
    -27,
    -40,
    -63,
    -61,
    -38,
    -42,
    -68,
    -62,
    -24,
    -23,
    -73,
    -114,
    -99,
    -69,
    -89,
    -127,
    -138,
    -111,
    -101,
    -97,
    -87,
    -83,
    -79,
    19,
    243,
    419,
    343,
    30,
    -227,
    -266,
    -174,
    -119,
    -109,
    -93,
    -67,
    -72,
    -65,
    -34,
    1,
    -13,
    -51,
    -57,
    -32,
    -1,
    -2,
    10,
    42,
    59,
    40,
    34,
    66,
    121,
    148,
    148,
    152,
    148,
    112,
    53,
    9,
    -34,
    -82,
    -146,
    -171,
    -149,
    -124,
    -140,
    -169,
    -178,
    -143,
    -104,
    -95,
    -101,
    -118,
    -147,
    -157,
    -119,
    -74,
    -44,
    -59,
    -86,
    -111,
    -118,
    -119,
    -111,
    -106,
    -102,
    -91,
    -79,
    -83,
    -95,
    -91,
    -82,
    -97,
    -149,
    -157,
    -133,
    -100,
    -91,
    -96,
    -119,
    -128,
    -110,
    -97,
    -102,
    -111,
    -99,
    -74,
    -65,
    -76
  ];

  const getData = () => {
    if (props.data.data) {
      const xyData = [];
      props.data.data.map((val, i) => {
        const dataSample = val.split(",");
        const ecgSample = parseInt(dataSample[0], 10);
        xyData.push({ x: (i+1) / 128, y: ecgSample / 2000 });
      });

      // newData.map((val, i) => {
      //   xyData.push({ x: (i+1) / 128, y: val / 2000 });
      // });

      console.log('xyData:', xyData)

      return {
        data: xyData,
        timestamp: moment(props.data.timestamp).format("YYYY-MM-DD, h.mm.ss a"),
      };
    }
  };

  const writeHTML = (frame) => {
    if (!frame) {
      return;
    }
    console.log("calling", frame);

    setIframe_ref(frame);
    let doc = frame.contentDocument;

    doc.open();
    doc.write(graphHtml.multipleGraphsHtml);
    doc.close();

    frame.style.width = "100%";
    // frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  };

  return (
    <iframe
      title="iF"
      onLoad={() => {
        const reading = getData();

        console.log("reading:", reading);

        iframe_ref.contentWindow.renderChartsByClassName(
          reading.data,
          reading.timestamp,
          props.name
        );
      }}
      src="about:blank"
      // scrolling="no"
      style={{ minHeight: 500, height: "auto" }}
      frameBorder="0"
      ref={writeHTML}
    />
  );
};

export default Iframe;
