import React from "react";
import { Route, Switch } from "react-router-dom";
import ManageSponsor from "./ManageSponsor";
import CreateSponsor from "./CreateSponsor";
import UpdateSponsor from "./UpdateSponsor";
import NotFound from "containers/NotFound";

const SponsorRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/manage`} component={ManageSponsor} />
    <Route path={`${match.url}/create`} component={CreateSponsor} />
    <Route path={`${match.url}/update/:id`} component={UpdateSponsor} />
    <Route component={NotFound} />
  </Switch>
);

export default SponsorRoutes;
