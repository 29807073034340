const point = [];

const page_width = 794;
const page_height = 1123;
const page_margin = 20;

const chartContainer = `<div style='width: 740pt; height: 190px; overflow:hidden; margin-left:-20px;'>
  <div class='chartContainer' style='width: 100%; height: 500px; margin-top: -8px'></div>
  </div>`;

export default {
  canvasHtml:
    `
      <!DOCTYPE html>
      <html>
      <body>
          <div style='width: calc(100% + 36px); height: 490px; overflow:hidden; margin-left:-20px; margin-top:-18px'>
              <div id='chartContainer' style='width: 100%; height: 500px;'></div>
          </div>
          <script src='https://canvasjs.com/assets/script/canvasjs.min.js'></script>
          <style>
              .canvasjs-chart-credit {
                  display:none;
              }
          </style>
          <script>
              var dataPoints = ` +
    JSON.stringify(point) +
    `;
              var dps = [];
              var xAxisStripLinesArray = [];
              var yAxisStripLinesArray = [];
              var color = "#EB0102";
              var xInterval = 250;
              var xGap = 5;
              var yInterval = 4800;
              var yGap = 300;
              for(var ii = -yInterval; ii <= yInterval; ii = ii+yGap){
                  yAxisStripLinesArray.push({value:ii, thickness:0.5, color: color});
              }
              for(var jj = xGap; jj <= xInterval; jj = jj+xGap){
                  xAxisStripLinesArray.push({value:jj, thickness:0.5, color: color});
              }
  
              var chart = new CanvasJS.Chart("chartContainer",{
                  theme: "light2",
                  // title: { text: "ECG" },
                  height: 500,
                  axisX: {
                      stripLines:xAxisStripLinesArray,
                      // title: "Milliseconds",
                      gridColor: color,
                      lineColor: color,
                      tickThickness: 0,
                      labelFormatter: function(e) { return ""; },
                      interval: xInterval
                  },
                  axisY: {
                      stripLines:yAxisStripLinesArray,
                      // title: "Heartrate",
                      minimum: -yInterval,
                      maximum: yInterval,
                      gridColor: color,
                      lineColor: color,
                      tickThickness: 0,
                      labelFormatter: function(e) { return ""; }
                  },
                  data: [{
                      type: "spline",
                      color:"black",
                      dataPoints : dps
                  }]
              });
              
              var addStripLines = function (xxStrip) {
                  alert(xxStrip);
                  alert(dps.length);
                  if(dps.length >= xInterval*2) {
                      const half = Math.ceil(xAxisStripLinesArray.length / 2);
                      alert(half);
                      xAxisStripLinesArray = xAxisStripLinesArray.splice(-half);
                  }
                  for(jj = xxStrip; jj < xInterval+xxStrip; jj = jj+xGap) {
                      xAxisStripLinesArray.push({value:jj, thickness:0.5, color: color});
                  }
              }
  
              var updateChart = function (xx, yy) {
                  dps.push({ x: xx, y: yy });
                  if (dps.length > xInterval) {
                      dps.shift();
                  }
                  if (xx >= jj) {
                      xAxisStripLinesArray.push({value:jj, thickness:0.5, color: color});
                      jj+=xGap;
                      xAxisStripLinesArray.shift();
                  }
                  if (xx >= xInterval && xx % xInterval == 0) {
                      // addStripLines(xx);
                  }
                  chart.render();
              };
              chart.render();
  
              var updateInterval = 1;
              let i=0;
              let xx = i;
              // setInterval(function(){
              //     if(i >= dataPoints.length) i = 0;
              //     const pp = dataPoints[i];
              //     i++;
              //     xx++;
              //     updateChart(xx, pp.y)
              // }, updateInterval);
          </script>
  
      </body>
      </html>     
      `,

  cljsHtml: `
      <!DOCTYPE html>
      <html lang="en">
      
          <head>
              <title>Using chart in HTML page</title>
              <meta charset="utf-8" />
              <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0">
              <!-- Flexbox styling to have the chart and header fill the page.
                  Chart will take as much space as possible. -->
              <style>
                  html,
                  body {
                      height: 100%;
                      margin: 0;
                  }
      
                  .box {
                      display: flex;
                      flex-flow: column;
                      height: 100%;
                  }
      
                  .box .row.header {
                      flex: 0 1 auto;
                  }
      
                  .box .row.content {
                      flex: 1 1 auto;
                  }
              </style>
          </head>
      
          <body class="box">
              <!-- Create div to render the chart into-->
              <div id="target" class="row content"></div>
      
              <!--IIFE assembly (lcjs.iife.js) is a standalone JS file, 
              which does not need any build tools, 
              such as NPM, to be installed-->
              <!--Script source must be defined in it's own script tag-->
              <script src="https://hrsolvent.com/graphLib/lcjs.iife.js"></script>
      
              <!--Actual chart related script tag-->
              <script>
                  // Extract required parts from LightningChartJS.
                  const {
                      lightningChart,
                      AxisScrollStrategies,
                      Themes,
                      AxisTickStrategies,
                      AutoCursorModes,
                      ColorHEX,
                      emptyFill,
                      SolidFill,
                      emptyLine,
                      SolidLine,
                      synchronizeAxisIntervals,
                      translatePoint,
                      UIOrigins,
                      UIElementBuilders,
                      UILayoutBuilders,
                  } = lcjs
                  
                  // Create a XY Chart.
                  const chart = lightningChart().ChartXY({
                      theme: Themes.lightNew,
                      container: 'target'
                  })
                  .setTitle('')
                  .setPadding({ bottom: 4, top: 10, right: 20, left: 10 })
                  .setMouseInteractions(false);
                  
                  // Create line series optimized for regular progressive X data.
                  const series = chart.addLineSeries({
                      dataPattern: {
                          // pattern: 'ProgressiveX' => Each consecutive data point has increased X coordinate.
                          pattern: 'ProgressiveX',
                          // regularProgressiveStep: true => The X step between each consecutive data point is regular (for example, always "1.0").
                          regularProgressiveStep: true,
                      }
                  })
                      // Destroy automatically outscrolled data (old data becoming out of scrolling axis range).
                      // Actual data cleaning can happen at any convenient time (not necessarily immediately when data goes out of range).
                      .setMaxPointCount(10000)
                      .setMouseInteractions(false)
                  
                  // Setup view nicely.
                  chart.getDefaultAxisY()
                      .setTitle('')
                      .setInterval(-1600, 1600)
                      .setScrollStrategy(AxisScrollStrategies.expansion)
                      .setMouseInteractions(false);
                  
                  chart.getDefaultAxisX()
                      .setTitle('')
                      .setInterval(0, 1000)
                      .setScrollStrategy(AxisScrollStrategies.progressive)
                      .setMouseInteractions(false);
                  
                  var updateChart = function (xx, yy) {
                      series.add({ x: xx, y: yy });
                  }
  
                  // Points that are used to generate a continuous stream of data.
                  const point = [

                  ];
      
                  // let i=0;
                  // let xx = i;
                  // setInterval(() => {
                  //     if(i>1580){ i = 0; }
                  //     const pp = point[i];
                  //     updateChart(xx, pp.y );
                  //     i++;
                  //     xx = xx+2;
                  // }, 1);
      
              </script>
          </body>
      </html>
      `,

  multipleGraphsHtml:
    `
    <!DOCTYPE html>
    <html>
    <head>
      <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0">
    </head>
    <body>
        <div id="pageContainer" style="display:none;">
          <div id="print">
              <div id="charts">
                  <div>
                      <h1 style="text-align: center;">ECG Report</h1>
                      <p style="text-align:center;"><span id="patient-name"></span></p>
                      <p style="text-align:center;"><span id="date"></span></p>
                  </div>
              </div>
              25 mm/sec
          </div>
        </div>
        <script src="https://code.jquery.com/jquery-3.6.0.slim.min.js" integrity="sha256-u7e5khyithlIdTpu22PHhENmPcRdFiHRjhAuHcs05RI=" crossorigin="anonymous"></script>
        <script src='https://canvasjs.com/assets/script/canvasjs.min.js'></script>
        <script src='https://html2canvas.hertzen.com/dist/html2canvas.min.js'></script>
        <script src='https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js'></script>
        
        <style>
            .canvasjs-chart-credit {
                display:none;
            }
            #charts {
              padding: 10px;
            }
            #print {
              padding: 10px;
            }
            #exportButton {
              width: 100%;
              background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
              border-radius: 0.5rem;
              box-sizing: border-box;
              color: #FFFFFF;
              display: flex;
              font-size: 16px;
              justify-content: center;
              padding: 1rem 1.75rem;
              text-decoration: none;
              width: 100%;
              border: 0;
              cursor: pointer;
              user-select: none;
              -webkit-user-select: none;
              touch-action: manipulation;
          }
        </style>
  
        <script>
          var page_width = ` +
    page_width +
    `;
          var page_height = ` +
    page_height +
    `;
          var page_margin = ` +
    page_margin +
    `;
          var chartContainer =\`` +
    chartContainer +
    `\`;
  
          var xAxisStripLinesArray = [];
          var yAxisStripLinesArray = [];
          var color = "#EB0102";
          var xInterval = 512;
          var xSize = xInterval/128;
          var xGap = 0.04;
          var yInterval = 1.2;
          var yGap = 0.1;
          var jj = xGap;
          var slim = 0.2;
          var fat = 0.6;

          var lineThickness2 = slim;
          var loop2 = 0;

          for(var ii = 0; ii <= yInterval; ii = ii+yGap){
            if (loop2 % 5 == 0) {
                lineThickness2 = fat;
            }

            yAxisStripLinesArray.push({value:ii, thickness:lineThickness2, color: color});
            yAxisStripLinesArray.push({value:-ii, thickness:lineThickness2, color: color});
              
            lineThickness2 = slim;
            loop2++;
          }

          var chartOptions = {
              // theme: "light2",
              // interactivityEnabled: false,
              // title: { text: "ECG" },
              height: 200,
              width: 720,
              axisX: {
                  // title: "mm",
                  // tickThickness: 0,
                  // interval: xSize,
                  stripLines: xAxisStripLinesArray,
                  gridThickness: 0,
                  gridColor: color,
                  lineColor: color,
                  tickColor: color,
                  labelFontColor: color,
                  labelFormatter: function(e) { return ""; },
              },
              axisY: {
                  // title: "mV",
                  // tickThickness: 0,
                  stripLines:yAxisStripLinesArray,
                  gridThickness: 0,
                  gridColor:color,
                  lineColor:color,
                  tickColor:color,
                  labelFontColor:color,
                  labelFormatter: function(e) { return ""; },
                  minimum: -yInterval,
                  maximum: yInterval,
              },
              data: [{
                  type: "line",
                  lineThickness: 1,
                  color:"black",
                  dataPoints : []
              }]
          };
            
          var getStripLines = function (start, end) {
              var stLines = [];
              var lineThickness = slim;

              var loop1 = 1;
              var newJ = xGap;
              if(start > 0) {
                  newJ = Math.ceil(start/xGap) * xGap;
              } else if(start < 0) {
                  newJ = Math.floor(start/xGap) * xGap
              }
              console.log(newJ)

              for(jj = newJ; jj <= end; jj = Math.round((jj+xGap)*100)/100) {
                if (loop1 % 5 == 0) {
                    lineThickness = fat;
                }
                stLines.push({value:jj, thickness:lineThickness, color: color});

                lineThickness = slim;
                loop1++;
              }
              return stLines;
          }
  
          function renderChartsByClassName(dataPoints, date, patientName='') {
              $('#date').html(date);
              $('#patient-name').html(patientName);
              var charts = [];
              var chunks = [];
              for (let c = 0; c < dataPoints.length; c += xInterval) {
                  const chunk = dataPoints.slice(c, c + xInterval);
                  chunks.push(chunk);
                  var para = document.createElement("div");
                  para.innerHTML = chartContainer;
                  document.getElementById("charts").appendChild(para);
              }
  
              var chartClassElements = document.getElementsByClassName("chartContainer");
              for (var i = 0; i < chartClassElements.length; i++) {
                  var start = chunks[i][0].x;
                  var end = chunks[i][chunks[i].length -1].x;
                  if(chunks[i].length < xInterval) {
                      for(let a=end*128; a<=xInterval*(i+1); a=a+1) {
                          end = a/128;
                          chunks[i].push({x:end, y:0});
                      }
                  }
                  chartOptions.data[0].dataPoints = chunks[i];
                  var stripLines = getStripLines(start, end);
                  chartOptions.axisX.stripLines = stripLines;
                  charts.push(new CanvasJS.Chart(chartClassElements[i], chartOptions));
                  charts[i].render();
              }
  
              $('#pageContainer').show();
          }
  
          var no_of_pages = 0;
          var pdf = new jspdf.jsPDF({orientation: 'p',unit: 'mm',format: 'a4', compressPdf: true});
          const filename  = 'ecgGraph.pdf';
  
          $("#exportButton").click(function(){
              html2canvas(document.querySelector("#print"), {scale: 2, useCORS:true}).then(canvas => {
                  // var canvas = $("#charts .canvasjs-chart-canvas").get(0);
                  var dataURL = canvas.toDataURL();
                  // pdf.addImage(dataURL, 'JPEG', 0, 0);
                  // pdf.addImage(canvas.toDataURL('image/png'), 'PNG', pageX, pageY, pixelsToMM(page_width), pixelsToMM(page_height));
                  // index = index + 1;
                  // addPage(pages, index);
                  // pdf.save("download.pdf");
                  // var blob = pdf.output('datauri', {filename:filename});
                  window.ReactNativeWebView?.postMessage(dataURL);
              });
              
          });
        </script>
  
    </body>
    </html>     
    `,
};
